import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminReturnLotterySetApi,
  confirmOrderApi,
  getOrderByIdApi,
  getOrderLogsByOrderIdApi,
  uploadSlipApi,
} from "../../api/order";
import { getProfileUserByApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import { IOrder, IOrderLogs } from "../../interface/order";
import { IUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppConfirm, useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const OrderDetail = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { confirm } = useAppConfirm();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<IOrder>();
  const [orderLogs, setOrderLogs] = useState<IOrderLogs[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [user, setUser] = useState<IUser>();
  const [selectedAction, setSelectedAction] = useState<number>(1);
  const [uploadSlipModal, setUploadSlipModal] = useState(false);
  const [uploadingSlip, setUploadingSlip] = useState(false); // New state for upload loading

  const getStatus = (status?: number): ReactNode => {
    switch (status) {
      case 0:
        return <span className="text-danger">ยกเลิก/หมดเวลา</span>;
      case 1:
        return <span className="text-success">สำเร็จ</span>;
      case 2:
        return <span className="text-small">จอง</span>;
      case 3:
        return <span className="text-info">รอชำระเงิน</span>;
      case 4:
        return <span className="text-warning">รอยืนยัน</span>;
      case 5:
        return <span className="text-warning">รอยืนยัน</span>;
      default:
        return <span className="text-light">-</span>;
    }
  };

  const getOrderById = async (): Promise<IOrder> => {
    try {
      const { data } = await getOrderByIdApi(id!);
      setOrder(data);
      await getOrderLogsByOrderId(id!);
      return data;
    } catch (e: any) {
      throw e;
    }
  };

  const getProfileUserById = async (uid: string) => {
    try {
      const { data } = await getProfileUserByApi(uid);
      setUser(data);
    } catch (e: any) {
      throw e;
    }
  };

  const getOrderLogsByOrderId = async (id: string) => {
    try {
      const { data } = await getOrderLogsByOrderIdApi(id);
      setOrderLogs(data ?? []);
    } catch (e: any) {
      throw e;
    }
  };

  const initData = async () => {
    try {
      setLoading(true);
      const order = await getOrderById();
      await Promise.all([getProfileUserById(order.userId)]);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const confirmOrder = async (id: string, status: 0 | 1) => {
    try {
      setLoading(true);
      await confirmOrderApi({ id, status });
      await Promise.all([getOrderById()]);
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const adminReturnLotterySet = async (lotterySetId: string) => {
    try {
      const isConfirmed = await confirm({
        title: "ยืนยันการทำรายการ",
        description: "ยืนยันการลบข้อมูล",
        textCancel: "ยกเลิก",
        classBtnCancel: "btn btn-light",
        textConfirm: "ยืนยัน",
        classBtnConfirm: "btn btn-danger",
      });

      if (!isConfirmed) return;

      setLoading(true);
      await adminReturnLotterySetApi(id!, { lotterySetId });
      await Promise.all([getOrderById()]);
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const handleUploadSlip = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fileInput = e.currentTarget.elements[0] as HTMLInputElement;
    const file = fileInput.files?.[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        setUploadingSlip(true); // Set loading state to true
        const base64data = reader.result as string;
        await uploadSlipApi({ id: id!, slip: base64data });
        openSnackbar("อัปโหลดหลักฐานการชำระเงินสำเร็จ");
        setUploadSlipModal(false);
        await initData();
      } catch (e: any) {
        let msg;
        if (typeof e?.response?.data?.message === "string") {
          msg = e?.response?.data?.message;
        }
        openSnackbar(msg || "Error: Something wrong.");
      } finally {
        setUploadingSlip(false); // Reset loading state
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);
  return (
    <>
      <Breadcrumb
        title={"รายละเอียด"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "คำสั่งซื้อ", link: "/orders" },
          { title: "รายละเอียด" },
        ]}
      />

      <h4 className="mb-2">
        หมายเลขคำสั่งซื้อ {order?.orderNo ?? ""} {getStatus(order?.status)}{" "}
      </h4>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-muted tx-13">ผู้ซื้อ</span>
                      <br />
                      <div
                        className="hover-underline cursor-pointer"
                        onClick={() =>
                          navigate(
                            `${process.env.PUBLIC_URL}/users/edit/${order?.userId}`
                          )
                        }
                      >
                        {user?.firstName} {user?.lastName}
                      </div>
                      <span className="tx-13 text-muted">
                        รหัส {user?.userNo}
                      </span>
                    </div>
                    <div className="col">
                      <span className="text-muted tx-13">เบอร์โทรศัพท์</span>
                      <br />
                      {user?.phoneNumber}
                    </div>
                    <div className="col">
                      <span className="text-muted tx-13">สถานะ</span>
                      <br />
                      {!!user ? (user?.status === 1 ? "ใช้งาน" : "ระงับ") : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานะคำสั่งซื้อ</h6>
          <div className="card">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>สถานะ</th>
                    <th>วันที่</th>
                    <th>เวลา</th>
                    <th>โดย</th>
                  </tr>
                </thead>
                <tbody>
                  {orderLogs.length > 0 ? (
                    orderLogs.map((logs) => {
                      return (
                        <tr key={logs.id}>
                          <td className="name">{logs.remark}</td>
                          <td className="unit">
                            {moment(logs.createdAt).format("DD/MM/yyyy")}
                          </td>
                          <td className="price">
                            {moment(logs.createdAt).format("hh:mm:ss")}
                          </td>
                          <td>{logs.createdBy}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        ไม่พบรายการ
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <div className="content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 50 }}></th>
                        <th>เลข</th>
                        <th>จัดชุด/จำนวน</th>
                        <th>สกุลเงิน</th>

                        {order?.currency === "KRW" ? (
                          <>
                            <th>ค่าเงิน THB/KRW</th>
                            <th>ราคา(₩)</th>
                            <th>อื่นๆ(₩)</th>
                            <th>รวม(₩)</th>
                          </>
                        ) : (
                          <>
                            <th>ราคา(บาท)</th>
                            <th>อื่นๆ(บาท)</th>
                            <th>รวม(บาท)</th>
                          </>
                        )}
                        <th style={{ width: 50 }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderItems.map((item, index) => {
                        return (
                          <tr key={item.lotterySetId}>
                            <td>{index + 1}</td>
                            <td>
                              <span className="h5">
                                {item.lotterySet.number}
                              </span>
                            </td>
                            <td>{item.lotterySet.totalInSet} ใบ</td>
                            <td>{order.currency}</td>

                            {order.currency !== "THB" ? (
                              <>
                                <td>{order.conversionRate.toFixed(2)}</td>
                                <td>
                                  {(
                                    item.price * order.conversionRate
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td>
                                  {(
                                    item.serviceCharge * order.conversionRate
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td>
                                  {(
                                    (item.price + item.serviceCharge) *
                                    order.conversionRate
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {item.price.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td>
                                  {item.serviceCharge.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td>
                                  {(
                                    item.price + item.serviceCharge
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </>
                            )}
                            <td>
                              {[2, 3, 5].includes(order.status) && (
                                <button
                                  onClick={() => {
                                    adminReturnLotterySet(item.lotterySetId);
                                  }}
                                  className="btn btn-outline-primary  mb-1 pt-2"
                                >
                                  <span className="fa fa-trash-o"></span>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row text-right">
                <div className="col-12 border-bottom border-top pt-4 pb-4 ">
                  <span className="h5 ">
                    รวมทั้งหมด{" "}
                    {order?.totalLottery.toLocaleString("en-US") ?? 0} ใบ
                  </span>
                </div>
                <div className="col-12 border-bottom border-top pt-4 pb-4 ">
                  {order?.currency === "KRW" ? (
                    <span className="h4">
                      ราคาทั้งหมด ₩
                      {(
                        (order?.totalAmount ?? 1) * (order?.conversionRate ?? 1)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) ?? 0}
                    </span>
                  ) : (
                    <span className="h4">
                      ราคาทั้งหมด ฿
                      {order?.totalAmount.toLocaleString("en-US") ?? 0}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Slip */}
      {order?.paymentMethod === "โอนเงิน" && (
        <div className="row mb-4">
          <div className="col-12 ">
            <h6 className="text-muted">หลักฐานการชำระเงิน</h6>
            <div className="card ">
              <div className="card-body">
                <div className="col-lg-6 m-auto">
                  <div className="card">
                    <div
                      className="card-body text-center cursor-pointer"
                      onClick={() => {
                        setShowImage({
                          show: true,
                          src: getSrcImage(order?.slip),
                        });
                      }}
                    >
                      <img
                        src={getSrcImage(order?.slip)}
                        alt=""
                        style={{
                          height: 500,
                          width: "auto",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {order?.status === 5 && (
                  <div className="text-center">
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => setUploadSlipModal(true)}
                    >
                      อัปโหลดหลักฐานการชำระเงินใหม่
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Slip */}

      <div
        className={`modal fade ${uploadSlipModal ? "show" : ""}`}
        id="uploadSlipModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="uploadSlipModalLabel"
        style={{ display: uploadSlipModal ? "block" : "none" }} // Control modal display
      >
        <div
          className={`modal-backdrop fade ${uploadSlipModal ? "show" : ""}`}
          style={{ zIndex: 0 }}
        />
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadSlipModalLabel">
                อัปโหลดหลักฐานการชำระเงิน
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => setUploadSlipModal(false)} // Close modal
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleUploadSlip}>
                <div className="form-group">
                  <label htmlFor="slip">เลือกไฟล์หลักฐานการชำระเงิน</label>
                  <input
                    type="file"
                    className="form-control"
                    id="slip"
                    name="slip"
                    accept="image/*"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={uploadingSlip}
                >
                  {uploadingSlip ? "กำลังอัปโหลด..." : "อัปโหลด"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {order?.status === 5 && (
        <div className="row mb-4">
          <div className="col-12">
            <div className="card ">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <select
                      className="form-control"
                      value={selectedAction + ""}
                      onChange={(e) => {
                        setSelectedAction(+e.target.value);
                      }}
                    >
                      <option value="1">ยืนยัน</option>
                      <option value="0">ยกเลิก</option>
                    </select>
                  </div>
                  <button
                    className="btn btn-success ml-auto mr-2"
                    onClick={() => {
                      confirmOrder(order.id, selectedAction as 0 | 1);
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};
export default OrderDetail;
