import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { selectApp } from "../../redux/app.reducer";
import { useAppSelector } from "../../redux/hooks";

const Deposit = () => {
  const { pathname } = useLocation();
  const { badge } = useAppSelector(selectApp);

  return (
    <>
      <Breadcrumb
        title={"กระเป๋าเงิน"}
        children={[{ title: "หน้าแรก", link: "/" }, { title: "กระเป๋าเงิน" }]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/deposit`}
                className={`nav-link ${
                  pathname.split("/").length === 3 ? "" : "active"
                }`}
              >
                คำขอใหม่
                {badge.newWithdraw > 0 && (
                  <span className="badge badge-pill badge-danger ml-1">
                    {badge.newWithdraw}
                  </span>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/deposit/history`}
                className={`nav-link ${
                  pathname.split("/").length === 3 ? "active" : ""
                }`}
              >
                ประวัติ
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  );
};
export default Deposit;
