import { AxiosResponse } from "axios";
import { IWalletWithPagignation } from "../interface/wallet";
import { http } from "../utils/http";

export const filterWalletsApi = async (payload: {
  status?: string;
  type?: string;
  userId?: string;
  orderNo?: string;
  userName?: string;
  isOrderLottery?: string;
  format?: "csv";
  begin?: number;
  end?: number;
  page?: number;
  limit?: number;
}): Promise<AxiosResponse<IWalletWithPagignation>> => {
  return await http.get("/v1/admin/wallets/filter", payload);
};

export const filterWalletsCsvApi = async (payload: {
  status?: string;
  type?: string;
  userId?: string;
  orderNo?: string;
  userName?: string;
  isOrderLottery?: string;
  begin?: number;
  end?: number;
}): Promise<AxiosResponse<IWalletWithPagignation>> => {
  return await http.get("/v1/admin/wallets/filter/csv", payload);
};

export const confirmWalletByIdApi = async (payload: {
  walletId: string;
  action: "approve" | "reject";
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/wallets/confirm`, payload);
};

export const confirmDepositByIdApi = async (payload: {
  depositId: string;
  action: 0 | 1;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/deposit/${payload.depositId}`, {
    ...payload,
    status: payload.action,
    id: payload.depositId,
  });
};

export const createDepositApi = async (payload: {
  totalAmount: number;
  userId: string;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/wallets/deposit/${payload.userId}`, {
    totalAmount: payload.totalAmount,
  });
};
