import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProfileUserByApi, updateUserProfileByIdApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import { IAdminUpdateUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";
const $ = require("jquery");
$.dropify = require("dropify");

const UserEdit = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [userNo, setUserNo] = useState<number>();
  const [user, setUser] = useState<IAdminUpdateUser>({} as IAdminUpdateUser);
  const [loading, setLoading] = useState(false);

  const initDropify = (defaultFile?: string) => {
    const drEvent = $(".dropify").dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
    });

    drEvent.on("dropify.afterClear", function (event, element) {
      setUser((prev) => ({
        ...prev,
        picture: "",
      }));
    });
  };

  const getProfileUserById = async () => {
    try {
      setLoading(true);

      const { data } = await getProfileUserByApi(id!);
      setUser(data as IAdminUpdateUser);
      setUserNo(data.userNo);
      initDropify(data.picture);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/users`);
    }
  };

  const updateUserProfileById = async (payload: IAdminUpdateUser) => {
    try {
      setLoading(true);
      await updateUserProfileByIdApi(id!, payload);
      await getProfileUserById();
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    getProfileUserById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: `${user.firstName ?? ""} ${user.lastName ?? ""}` },
          { title: "ประวัติส่วนตัว" },
        ]}
      />

      <div className="row">
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  <span className="nav-link active">ประวัติส่วนตัว</span>
                  {/* <Link className="nav-link" to={`/users/edit/${id}`}>ประวัติส่วนตัว</Link> */}
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">กระเป๋าเงิน</span> */}
                  <Link className="nav-link" to={`/users/income/${id}`}>
                    กระเป๋าเงิน
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ประวิติการใช้งาน</span> */}
                  <Link className="nav-link" to={`/users/history/${id}`}>
                    ประวิติการใช้งาน
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">บัญชีรับรางวัล</span> */}
                  <Link className="nav-link" to={`/users/book-bank/${id}`}>
                    บัญชีรับรางวัล
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  {/* <span className="nav-link active">ทีมงาน</span> */}
                  <Link className="nav-link" to={`/users/team/${id}`}>
                    ทีมงาน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form
                className="forms-sample"
                onSubmit={(e) => {
                  e.preventDefault();
                  updateUserProfileById(user);
                }}
              >
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    รูป
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="file"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setUser((prev) => ({
                            ...prev,
                            picture: reader.result as string,
                          }));
                        };
                      }}
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="400"
                      data-allowed-file-extensions="jpg png jpeg"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    รหัส
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      defaultValue={userNo ?? ""}
                      className="form-control ml-auto"
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    รหัสตัวแทน
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      defaultValue={user.referralCode ?? ""}
                      className="form-control ml-auto"
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    รหัสอัพไลน์
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder=""
                      value={user?.referralBy ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          referralBy: e.target.value ?? "",
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12 border-bottom">
                    <h5>ข้อมูลส่วนตัว</h5>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    ชื่อ
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder=""
                      value={user?.firstName ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    นามสกุล
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder=""
                      value={user?.lastName ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    วันเกิด
                  </label>
                  <div className="col-sm-9">
                    <div className="input-group d-flex align-items-center">
                      <input
                        type="date"
                        className="form-control"
                        value={
                          user?.birthDate !== ""
                            ? moment(user?.birthDate).format("yyyy-MM-DD")
                            : ""
                        }
                        onChange={(e) => {
                          setUser((prev) => ({
                            ...prev,
                            birthDate: moment(e.target.value).format(
                              "yyyy-MM-DD 00:00:00.000"
                            ),
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    รหัสบัตรประชาชน
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      value={user?.idCardNo}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          idCardNo: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12 border-bottom">
                    <h5>ข้อมูลในการติดต่อ</h5>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    เบอร์โทร
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      value={user?.phoneNumber ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          phoneNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control ml-auto"
                      value={user?.email ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    Facebook
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      value={user?.facebook ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          facebook: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    Line ID
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      value={user?.line ?? ""}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          line: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12 border-bottom">
                    <h5>สถานนะ</h5>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-3 col-form-label">
                    สถานะ
                  </label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input
                        type="checkbox"
                        checked={user?.status === 1}
                        onChange={(e) => {
                          setUser((prev) => ({
                            ...prev,
                            status: e.target.checked ? 1 : 0,
                          }));
                        }}
                      />
                      <span className="toggle-slider round"></span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="row mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary ml-auto mr-2"
                    >
                      บันทึกข้อมูล
                    </button>
                    <button
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/users`);
                      }}
                      className="btn btn-light mr-2"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEdit;
