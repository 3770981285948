interface IMenu {
  id: string;
  link: string;
  icon: string;
  title: string;
  badgeKey?: "order" | "newOrderProductsUpgrade" | "newDeposit" | "newWithdraw";
}

const Menu: IMenu[] = [
  {
    id: "dashboard",
    link: "dashboard",
    icon: "mdi mdi-clipboard-text-outline menu-icon",
    title: "หน้าหลัก",
  },
  {
    id: "userMember",
    link: "users",
    icon: "mdi mdi-account-circle menu-icon",
    title: "สมาชิก",
  },
  {
    id: "order",
    link: "orders",
    icon: "mdi mdi-cart menu-icon",
    title: "คำสั่งซื้อ",
    badgeKey: "order",
  },
  // { id: "productsUpgrade", link: "products-upgrade/orders", icon: "mdi mdi-shopping menu-icon", title: "สินค้าอัพเกรด", badgeKey: "newOrderProductsUpgrade" },
  // { id: "report", link: "report", icon: "mdi mdi-chart-bar menu-icon", title: "รายงาน" },
  // {
  //   id: "withdraw",
  //   link: "withdraw",
  //   icon: "mdi mdi-cash menu-icon",
  //   title: "ถอนเงิน",
  //   badgeKey: "newWithdraw",
  // },
  {
    id: "withdraw",
    link: "deposit",
    icon: "mdi mdi-cash menu-icon",
    title: "กระเป๋าเงิน",
    badgeKey: "newWithdraw",
  },
  {
    id: "lottery",
    link: "lottery",
    icon: "mdi mdi-ticket-confirmation menu-icon",
    title: "ลอตเตอรี่",
  },
  {
    id: "news",
    link: "news",
    icon: "mdi mdi-bullhorn menu-icon",
    title: "ประกาศข่าว",
  },
  // { id: "banner", link: "banners", icon: "mdi mdi-rocket menu-icon", title: "แบนเนอร์" },
  // { id: "affiliate", link: "affiliate", icon: "mdi mdi-share-variant menu-icon", title: "ลิ้งแนะนำ" },
  {
    id: "setting",
    link: "setting",
    icon: "mdi mdi-settings menu-icon",
    title: "ตั้งค่า",
  },
];
export default Menu;
