import { FC, useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getRuleByUserIdApi } from "../../api/core";
import Datatable, { DatatableElement } from "../../component/datatable";
import { IRule } from "../../interface/rule";
import { IUpdateAdmin, IUser } from "../../interface/user";
import useSnackbar from "../../utils/snackbar";

interface IEidtAdminModal {
  user: IUser;
  show: boolean;
  toggle: Function;
  onSubmit: (id: string, data: IUpdateAdmin) => void;
}

const EditAdminModal: FC<IEidtAdminModal> = ({
  user,
  show,
  toggle,
  onSubmit,
}) => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const tableRules = useRef<DatatableElement>(null);
  const [reqAddUser, setReqAddUser] = useState<IUpdateAdmin>({
    firstName: "",
    lastName: "",
    status: 0,
    rule: {
      dashboard: false,
      userMember: false,
      order: false,
      productsUpgrade: false,
      report: false,
      withdraw: false,
      lottery: false,
      news: false,
      banner: false,
      affiliate: false,
      setting: false,
    },
  });
  const [rules, setRules] = useState<
    { id: string; title: string; selected?: boolean }[]
  >([
    { id: "dashboard", title: "หน้าหลัก" },
    { id: "userMember", title: "สมาชิก" },
    { id: "order", title: "คำสั่งซื้อ" },
    // { id: "productsUpgrade", title: "สินค้าอัพเกรด" },
    // { id: "report", title: "รายงาน" },
    { id: "withdraw", title: "กระเป๋าเงิน" },
    { id: "lottery", title: "ลอตเตอรี่" },
    { id: "news", title: "ประกาศข่าว" },
    // { id: "banner", title: "แบนเนอร์" },
    // { id: "affiliate", title: "ลิ้งแนะนำ" },
    { id: "setting", title: "ตั้งค่า" },
  ]);

  const getRuleByUserId = async () => {
    try {
      const { data } = await getRuleByUserIdApi(user.id);
      setReqAddUser({
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        rule: data,
      });

      setRules((prev) => prev.map((e) => ({ ...e, selected: data[e.id] })));
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      toggle();
    }
  };

  useEffect(() => {
    setReqAddUser({
      firstName: "",
      lastName: "",
      status: 0,
      rule: {
        dashboard: false,
        userMember: false,
        order: false,
        productsUpgrade: false,
        report: false,
        withdraw: false,
        lottery: false,
        news: false,
        banner: false,
        affiliate: false,
        setting: false,
      },
    });

    setRules([
      { id: "dashboard", title: "หน้าหลัก" },
      { id: "userMember", title: "สมาชิก" },
      { id: "order", title: "คำสั่งซื้อ" },
      // { id: "productsUpgrade", title: "สินค้าอัพเกรด" },
      // { id: "report", title: "รายงาน" },
      { id: "withdraw", title: "ถอนเงิน" },
      { id: "lottery", title: "ลอตเตอรี่" },
      { id: "news", title: "ประกาศข่าว" },
      // { id: "banner", title: "แบนเนอร์" },
      // { id: "affiliate", title: "ลิ้งแนะนำ" },
      { id: "setting", title: "ตั้งค่า" },
    ]);

    if (show) {
      getRuleByUserId();
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <Modal size="lg" isOpen={show} toggle={() => toggle()} centered={false}>
      <div className="modal-title modal-header">
        <h5 className="modal-title">เพิ่มผู้ดูแลระบบ</h5>
        <button
          type="button"
          onClick={() => toggle()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form
          autoComplete="false"
          onSubmit={(e) => {
            e.preventDefault();
            let selectedRules = tableRules.current?.selected();
            let rule: IRule = {
              dashboard: false,
              userMember: false,
              order: false,
              productsUpgrade: false,
              report: false,
              withdraw: false,
              lottery: false,
              news: false,
              banner: false,
              affiliate: false,
              setting: false,
            };
            for (const r of selectedRules) {
              rule[r.id] = true;
            }
            reqAddUser.rule = rule;
            onSubmit(user.id, reqAddUser);
          }}
        >
          <div className="border-bottom mb-3">
            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="required">
                  ชื่อ
                </label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input
                  className="form-control"
                  type="text"
                  required
                  value={reqAddUser.firstName}
                  onChange={(e) => {
                    setReqAddUser((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="">นามสกุล</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input
                  className="form-control"
                  type="text"
                  value={reqAddUser.lastName}
                  onChange={(e) => {
                    setReqAddUser((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="name">สถานะ</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <label className="toggle-switch toggle-switch-success">
                  <input
                    type="checkbox"
                    checked={reqAddUser.status === 1}
                    onChange={(e) => {
                      setReqAddUser((prev) => ({
                        ...prev,
                        status: e.target.checked ? 1 : 0,
                      }));
                    }}
                  />
                  <span className="toggle-slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <h4>กำหนดสิทธิ์การใช้งาน</h4>
            <Datatable
              id="dataTable3"
              ref={tableRules}
              data={rules}
              columns={[
                {
                  isCheckbox: true,
                  title: "",
                  data: function (row, type, set) {
                    return "";
                  },
                },
                {
                  title: "สิทธิ์การเข้าใช้งาน",
                  width: "100%",
                  data: "title",
                },
              ]}
            />
          </div>
          <div>
            <div className="row mt-3">
              <button
                onClick={() => toggle()}
                className="btn btn-light ml-auto mr-2"
              >
                ยกเลิก
              </button>
              <button type="submit" className="btn btn-primary mr-2">
                บันทึกข้อมูล
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditAdminModal;
