import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import debouce from "lodash/debounce";

const firebaseConfig = {
  apiKey: "AIzaSyDtlr9ka5MHBG6REKZ1F0tkvMtplFboJiA",
  authDomain: "salakthai-b8951.firebaseapp.com",
  projectId: "salakthai-b8951",
  storageBucket: "salakthai-b8951.appspot.com",
  messagingSenderId: "704768356923",
  appId: "1:704768356923:web:b26f3248c7fa9ca3bba21e",
  measurementId: "G-SPTF6PV9DN",
};

const msgKey =
  "BP7RHkPi2PCDH9uTCme1U1IJPTs1veVBayXGVioplpZ0QKdCnBpk8qbgd33QCbHGnlVWywshGcfrI2SuN64EIHo";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (
  cb: (data: MessagePayload) => void
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      cb(payload);
      debouncedPlaySound();
      resolve(payload);
    });
  });

async function playSound() {
  var audio = new Audio("/audio/new-order.mp3");
  await audio.play();
}

// Usage:
// This will create a debounced version of the playSound function
// that, when called repeatedly, will only actually invoke playSound
// at most once every 1 second.
var debouncedPlaySound = debouce(async () => {
  for (let i = 0; i < 5; i++) {
    await playSound();
  }
}, 1000);
