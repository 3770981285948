import { AxiosResponse } from "axios";
import {
  IOrder,
  IOrderLogs,
  IOrderRewardGroupByUserId,
  IOrderSummary,
  IOrderWithPagignation,
} from "../interface/order";
import { http } from "../utils/http";

export const filterOrdersApi = async (payload: {
  paymentMethod?: string;
  periodId?: string;
  userId?: string;
  userName?: string;
  orderNo?: string;
  status?: string;
  begin?: number;
  end?: number;
  page?: number;
  limit?: number;
  type?: "csv";
}): Promise<AxiosResponse<IOrderWithPagignation>> => {
  return await http.get("/v1/admin/orders/filter", payload);
};

export const filterOrderSummaryApi = async (
  periodId: string
): Promise<AxiosResponse<IOrderSummary>> => {
  return await http.get(`/v1/admin/orders/filter/summary/${periodId}`);
};

export const getOrderByIdApi = async (
  id: string
): Promise<AxiosResponse<IOrder>> => {
  return await http.get(`/v1/admin/orders/${id}`);
};

export const getOrderLogsByOrderIdApi = async (
  orderId: string
): Promise<AxiosResponse<IOrderLogs[]>> => {
  return await http.get(`/v1/admin/order-logs/${orderId}`);
};

export const getOrderSummaryDashboardApi = async (
  periodId: string
): Promise<
  AxiosResponse<
    {
      date: string;
      totalOrder: number;
      totalAmount: number;
    }[]
  >
> => {
  return await http.get(`/v1/admin/orders/summary-dashboard/${periodId}`);
};

export const confirmOrderApi = async (payload: {
  id: string;
  status: 0 | 1;
}): Promise<AxiosResponse<any>> => {
  return await http.post(`/v1/admin/orders/confirm`, payload);
};

export const adminReturnLotterySetApi = async (
  orderId: string,
  payload: { lotterySetId: string }
): Promise<AxiosResponse<any>> => {
  return await http.post(`/v1/admin/orders/${orderId}/return`, payload);
};

export const findRewardByPeriodIdGroupByUserIdApi = async (
  periodId: string
): Promise<AxiosResponse<IOrderRewardGroupByUserId[]>> => {
  return await http.get(`/v1/admin/orders/history/${periodId}/rewards`);
};

export const uploadSlipApi = async (payload: {
  id: string;
  slip: string;
}): Promise<AxiosResponse<any>> => {
  return await http.post(`/v1/admin/orders/submit`, payload);
};
