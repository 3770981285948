import React, { useEffect } from "react";
import {
  subsctibeToTopicApi,
  getBadgeOrderApi,
  getBadgeOrderUpgradeApi,
  getBadgeNewWithdrawApi,
} from "../api/core";
import { getToken, onMessageListener } from "../firebase";
import { setBadge } from "../redux/app.reducer";
import { useAppDispatch } from "../redux/hooks";
import { MessagePayload } from "firebase/messaging";

const FCMComponent = () => {
  const dispatch = useAppDispatch();

  const initFcm = async () => {
    try {
      const fcmToken = await getToken();
      if (!!fcmToken) {
        localStorage.setItem("fcm_token", fcmToken);
        await subsctibeToTopicApi(fcmToken);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBadgeOrder = async () => {
    try {
      const { data } = await getBadgeOrderApi();
      dispatch(setBadge({ type: "order", total: data.total }));
    } catch (error) {
      throw error;
    }
  };

  const getBadgeOrderUpgrade = async () => {
    try {
      const { data } = await getBadgeOrderUpgradeApi();
      dispatch(
        setBadge({ type: "newOrderProductsUpgrade", total: data.total })
      );
    } catch (error) {
      throw error;
    }
  };

  const getBadgeNewWithdraw = async () => {
    try {
      const { data } = await getBadgeNewWithdrawApi();
      dispatch(setBadge({ type: "newWithdraw", total: data.total }));
    } catch (error) {
      throw error;
    }
  };

  const listener = async (payload: MessagePayload) => {
    let data = payload.data;
    console.log(data);

    switch (data?.type) {
      case "wallet":
      case "deposit":
        await Promise.all([getBadgeNewWithdraw()]);
        break;
      case "order":
        await Promise.all([getBadgeOrder()]);
        break;
      case "order_upgrade":
        await Promise.all([getBadgeOrderUpgrade()]);
        break;
      default:
        console.log(data?.action_type);
    }
  };

  useEffect(() => {
    initFcm();
    getBadgeOrder();
    getBadgeNewWithdraw();
    getBadgeOrderUpgrade();
    onMessageListener(listener);
  }, []);

  return <></>;
};

export default FCMComponent;
